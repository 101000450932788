import React from 'react';

const Comp = () => {
  return (
    <div>
      hello
    </div>
  )
}

const Playground = () => {
  const list = [1,2,3,4,5];

  const onClickBtn = (e) => {
    console.log(e.target.name);
  }

  return (
    <div >
      <button name="jh" onClick={onClickBtn}>Hello</button>
    </div>
  );
};


export default Playground;
