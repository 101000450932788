import React, { useEffect, useRef, useState } from "react";
import { Form, useActionData, useLocation, useOutletContext, useNavigation } from "react-router-dom";
import { changePlanQuery, generatePlanQuery, rollbackPlanQuery, savePlanQuery } from "../../../queries/planQueries";
import { makeParamsFromFormData, randomNumber } from "../../../util/util";
import {POST_METHOD} from "../../../const/const";
import { useToastPopup } from "../../../hooks/useToastPopup";
import {IFRAME_HOST} from "../../../server/host";
import { Helmet } from 'react-helmet-async';
import ConfirmPopup from '../../components/ConfirmPopup';
import GeneratingScreen from "../../components/GeneratingScreen";
import { useQueryClient } from '@tanstack/react-query';
import Loading from "../loading";

export const planAction =
  (queryClient) => async ({ request }) => {
    const param = makeParamsFromFormData(await request.formData());
    const newParam = {
      appCode: param.appCode,
      appProposalDto: [
        param
      ]
    }
    const json = await queryClient.fetchQuery(savePlanQuery(newParam));
    const changeJson = await queryClient.fetchQuery(changePlanQuery({
      projectName: param.projectName,
    }));

    console.log("changeJson", changeJson);


    if(json.result === "SUCCESS" && changeJson.result === "SUCCESS") {
      return randomNumber();
    } else {
      return "fail";
    }
  }

const Plan = () => {
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  
  // planLayout에 있는 Outlet을 통해 Props전달
  const { data, appCode, appProjectName } = useOutletContext();
  const location = useLocation();
  const [modi, setModi] = useState(data.modificationRequirements ?? "")
  const toastHook = useToastPopup();
  let actionResult = useActionData();
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const iframeRef = useRef(null);
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    type: null,
  });

 

  // useEffect(() => {
  //   console.log(modi)
  // }, [modi])
  useEffect(() => {
    setModi(data.modificationRequirements ?? "")
  }, [location.pathname]);

  useEffect(() => {
    if(!actionResult) return;
    
    if(actionResult !== "success") {
      toastHook.addToastPopup("수정사항이 저장되었습니다.", "success");
    } else {
      toastHook.addToastPopup("수정사항에 문제가 발생했습니다.", "error");
    }
  }, [actionResult])

  // src가 변경될 때마다 로딩 상태 초기화
  useEffect(() => {
    setIsLoading(true);
  }, [data.appProposalPath]);

  async function handleGenerate() {
    try {
      setIsGenerating(true);
      
      const json = await queryClient.fetchQuery(
        generatePlanQuery({
          projectName: appProjectName,
          modificationRequirements: `사용자 수정요청 경로페이지: '/${appProjectName}${data.appProposalPath}', 사용자 요청 수정사항: ${modi}, nextjs approuter 영역인 use clinent 와 use server 를 수정하지 않습니다.`
        })
      );

      if (json.result === "SUCCESS") {
        toastHook.addToastPopup("수정사항이 반영되었습니다.", "success");
      } else {
        toastHook.addToastPopup("화면 생성에 실패했습니다.", "error");
      }
      
    } catch (error) {
      console.error('Generate plan error:', error);
      toastHook.addToastPopup("화면 생성 중 오류가 발생했습니다.", "error");
    } finally {
      setIsGenerating(false);
    }
  }

  async function handleRollback() {
    try {
      setIsLoading(true);
      
      
      const json = await queryClient.fetchQuery(
        rollbackPlanQuery({
          projectName: appProjectName,
        })
      );

      if (json.result !== "SUCCESS") {
        toastHook.addToastPopup("롤백에 실패했습니다.", "error");
        return;
      }

      if (iframeRef.current) {

        setTimeout(() => {
          setIsLoading(true);
          iframeRef.current.src = `${IFRAME_HOST}/${appProjectName}${data.appProposalPath}`;
          toastHook.addToastPopup("수정사항이 롤백되었습니다.", "success");
        }, 1000);
        // setIsLoading(false);
      }
      
    } catch (error) {
      console.error('Generate plan error:', error);
      toastHook.addToastPopup("롤백 중 오류가 발생했습니다.", "error");
    } 
  }


  const handleConfirm = async () => {
    if (confirmPopup.type === 'generate') {
      handleGenerate();
    } else if (confirmPopup.type === 'rollback') {
      handleRollback();
    }
    setConfirmPopup({ isOpen: false, type: null });
  };

  return (
    <>
      {navigation.state === "submitting" && <Loading />}
      <Helmet>
        <title>AiAppㅣ온라인 기획서</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="온라인 기획서 페이지 입니다. 간편한 온라인 기획서를 통해 나만의 앱을 만들어 보세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <Form method={POST_METHOD}>
        {
          Object.entries(data).map(([key, value]) => (
            key !== "modificationRequirements" ? <input key={key} type="hidden" name={key} value={value}/> : ""
          ))
        }
        <input type="hidden" name="appCode" value={appCode} />
        <input type="hidden" name="projectName" value={appProjectName} />
        <div className="bottom">
        
            <button 
              type="button" 
              onClick={() => setConfirmPopup({ isOpen: true, type: 'rollback' })}
              disabled={isGenerating}
            >
              수정 사항 초기화
            </button>
          
            <button 
              type="button" 
              disabled={isGenerating}
              onClick={() => setConfirmPopup({ isOpen: true, type: 'generate' })}
            >
              AI 화면 생성하기
            </button>
        
          <button disabled={isGenerating}>현재 페이지 저장하기</button>
        </div>
        <div className="tab_content-wrapper"> {/* tab contents */}
          <div id="tab1" className="tab_content active"> {/* tab1 */}
            <div className="d-flex gap30">
              <div>
                <div className="tit"><h3>{data.appProposalName}</h3></div>
                  <div className="appImg">
                    <div className="iframe-container">
                      <iframe 
                        ref={iframeRef}
                        title="앱 화면" 
                        src={`${IFRAME_HOST}/${appProjectName}${data.appProposalPath}`}
                        loading="lazy"
                        onLoad={() => setIsLoading(false)}
                      />
                      {(isLoading || isGenerating) && (
                        <div className="loading-overlay">
                          <GeneratingScreen 
                            message={isGenerating ? "AI가 화면을 생성하고 있습니다" : "화면을 불러오고 있습니다"} 
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              <div>
                <div className="tit d-flex align-items-center justify-content-between">
                  <h3>화면 및 기능 설명</h3>
                </div>
                <div className="sbox pre-wrap">{data.appProposalContent}</div>
              </div>
              <div>
                <ul className="tit d-flex align-items-center gap10"> {/* tab menu */}
                  <li id="con1" className="tab_item2 on"> {/* tab2-1 */}
                    <a href="#con2-1">
                      <button onClick={(e) => {e.preventDefault();}}><h3>수정 요청사항</h3></button>
                    </a>
                  </li>
                  <li id="con2" className="tab_item2"> {/* tab2-2 */}
                    <a href="#con2-2">
                      <button onClick={(e) => {e.preventDefault()}} className="d-flex align-items-center tooltip">
                        <h3>앱코드 생성</h3>
                        <img src={`${process.env.PUBLIC_URL}/img/icon_infoW.png`} alt="설명 아이콘" />
                        <div className="tooltiptext tooltip-bottom">
                          <h2>앱코드 생성이란?</h2>
                          <p>입력하신 수정사항(프롬프트)를 통해 앱 코드가 어떻게 진행되는지 확인하실 수 있으며, 추후 자동화를 위해 러닝을 위한 자료가 됩니다.</p>
                        </div>
                      </button>
                    </a>
                  </li>
                </ul>
                <div> {/* tab content */}
                  <div id="con2-1" className="tab_content2 active">
                    <textarea 
                      className="sbox" 
                      type="text" 
                      value={modi}
                      onChange={(e) => {setModi(e.target.value)}}
                      // defaultValue={data.modificationRequirements ?? ""}
                      name="modificationRequirements"
                      placeholder="이미지의 화면에서 수정하고 싶은 내용을 상세히 작성해주세요. &#13;&#10;&#13;&#10; ex&#41; 게시판 상세로 들어가는게 아니라 FAQ 같은 토글 방식으로 클릭시 하단에 공지사항을 볼 수 있게해주세요.버튼 배경 컬러를 #303030 으로 수정해 주세요. "></textarea>
                  </div>
                  <div id="con2-2" className="tab_content2">
                    <div className="appcodeWrap">
                      <div className="top d-flex align-items-center justify-content-between">
                        <div>
                          <button onClick={(e) => {e.preventDefault()}}>코드 생성하기</button>
                          <span>프레임워크 : Fluuter</span>
                        </div>
                        <button className="copyBtn"><img src="img/icon_copy.png" alt="복사하기 아이콘" />Copy Code</button>
                      </div>
                      <textarea className="appcode" placeholder="수정 요청사항에 수정하고 싶은 내용을 작성하고 'AI 화면 생성하기’를 눌러주세요"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <ConfirmPopup 
        isOpen={confirmPopup.isOpen}
        onClose={() => setConfirmPopup({ isOpen: false, type: null })}
        onConfirm={handleConfirm}
        title={confirmPopup.type === 'generate' ? 'AI 화면 생성' : '화면 복원'}
        message={
          confirmPopup.type === 'generate' 
            ? 'AI 화면을 생성하시겠습니까?' 
            : '화면을 복원하시겠습니까?'
        }
      />
    </>
  )
}

export default Plan